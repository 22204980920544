import React, { lazy, useEffect } from "react";
import { Switch, Redirect, withRouter, Route } from "react-router-dom";
import { Layout } from "antd";
import { connect } from "react-redux";
import authActions from "../redux/actions/Auth.actions";
import { useHistory, useLocation } from "react-router-dom";
// ROUTERS
import UserRoute from "../routers/UserRoute";

// COMPONENTS
import Header from "./Header";
import Footer from "./Footer";
import Sidenav from "./Sidenav";
import TopNavigation from "./TopNavigation.js";


// PAGES
//Dashboard
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));

//Products
const Products = lazy(() => import("../pages/products/Products.js"));
const AddNewProduct = lazy(() => import("../pages/products/AddNewProduct"));
const EditProduct = lazy(() => import("../pages/products/EditProduct"));
const ProductCategories = lazy(() =>
	import("../pages/products/ProductCategories")
);
const ProductsDisplay = lazy(() =>
	import("../pages/products/ProductsDisplay")
);
const EditProductCategory = lazy(() =>
	import("../pages/products/EditProductCategory")
);
const ProductTypes = lazy(() => import("../pages/products/ProductTypes"));
const EditProductType = lazy(() => import("../pages/products/EditProductType"));
const ProductGroups = lazy(() => import("../pages/products/ProductGroups"));
const EditProductGroup = lazy(() =>
	import("../pages/products/EditProductGroup")
);
const ProductBrands = lazy(() => import("../pages/products/ProductBrands"));
const EditProductBrand = lazy(() =>
	import("../pages/products/EditProductBrand")
);
const ProductUnits = lazy(() => import("../pages/products/ProductUnits"));
const EditProductUnit = lazy(() =>
	import("../pages/products/EditProductUnit")
);



const ProductPlans = lazy(() => import("../pages/products/ProductPlans"));
const ProductTags = lazy(() => import("../pages/products/ProductTags"));

//Customers
const Customers = lazy(() => import("../pages/customers/Customers"));
const CustomersLocations = lazy(() => import("../pages/customers/CustomersLocations"));
const AddNewCustomer = lazy(() => import("../pages/customers/AddNewCustomer"));
const EditCustomer = lazy(() => import("../pages/customers/EditCustomer"));
const CustomerGroups = lazy(() => import("../pages/customers/CustomerGroups"));

const EditCustomerGroup = lazy(() =>
	import("../pages/customers/EditCustomerGroup")
);
const CustomerOutlets = lazy(() =>
	import("../pages/customers/CustomerOutlets")
);
const AddNewCustomerOutlet = lazy(() =>
	import("../pages/customers/AddNewCustomerOutlet")
);
const EditCustomerOutlet = lazy(() =>
	import("../pages/customers/EditCustomerOutlet")
);
const CustomersUsers = lazy(() => import("../pages/customers/CustomersUsers"));
const ImportCustomers = lazy(() =>
	import("../pages/customers/ImportCustomers")
);
const BusinessCard = lazy(() => import('../pages/customers/BusinessCard'))
const AddBusinessCard = lazy(() => import('../pages/customers/AddBusinessCard'))
const EditBusinessCard = lazy(() => import('../pages/customers/EditBusinessCard'))

//Finance
const Invoice = lazy(() => import("../pages/finance/Invoice/Invoices"));
const AddInvoice = lazy(() => import("../pages/finance/Invoice/AddInvoice"));
const EditInvoice = lazy(() => import("../pages/finance/Invoice/EditInvoice"));
const License = lazy(() => import("../pages/finance/License"));
const Receipts = lazy(() => import("../pages/finance/Receipts"));
const Payments = lazy(() => import("../pages/finance/Payments"));
const Tax = lazy(() => import("../pages/finance/Tax"));
const EditTax = lazy(() => import("../pages/finance/EditTax"));
const Currencies = lazy(() => import("../pages/finance/Currencies"));
const FinanceReports = lazy(() => import("../pages/finance/FinanceReports"));
const PreviewInvoice = lazy(() => import("../pages/finance/Invoice/Print/PreviewInvoice.js"));

//Stock
const Suppliers = lazy(() => import("../pages/stock/Suppliers"));
const AddNewSupplier = lazy(() => import("../pages/stock/AddNewSupplier"));
const EditSupplier = lazy(() => import("../pages/stock/EditSupplier"));
const Warehouses = lazy(() => import("../pages/stock/Warehouses"));
const AddNewWarehouse = lazy(() => import("../pages/stock/AddNewWarehouse"));
const EditWarehouse = lazy(() => import("../pages/stock/EditWarehouse"));
const Purchases = lazy(() => import("../pages/stock/Purchases"));
const Transfers = lazy(() => import("../pages/stock/Transfers"));
const StockCount = lazy(() => import("../pages/stock/StockCount"));
const StockReports = lazy(() => import("../pages/stock/StockReports"));

//Sales

const SalesTasks = lazy(() => import("../pages/sales/SalesTasks"));
const SalesCallType = lazy(() => import("../pages/sales/SalesCallType"));
const EditSalesCallType = lazy(() => import("../pages/sales/EditSalesCallType"));
const SalesCallStatus = lazy(() => import("../pages/sales/SalesCallStatus"));
const EditSalesCallStatus = lazy(() => import("../pages/sales/EditSalesCallStatus"));
const SalesCallCategory = lazy(() => import("../pages/sales/SalesCallCategory"));
const EditSalesCallCategory = lazy(() => import("../pages/sales/EditSalesCallCategory"));

const Sales = lazy(() => import("../pages/sales/Sales"));
const AddNewSale = lazy(() => import("../pages/sales/AddNewSale"));
const EditSale = lazy(() => import("../pages/sales/EditSale"));
const Leads = lazy(() => import("../pages/sales/Leads"));
const AddNewLead = lazy(() => import("../pages/sales/AddNewLead"));
const EditLead = lazy(() => import("../pages/sales/EditLead"));
const Quotations = lazy(() => import("../pages/sales/Quotations"));
const AddQuotation = lazy(() => import("../pages/sales/AddQuotation"));
const EditQuotation = lazy(() => import("../pages/sales/EditQuotation"));
const PreviewQuotation = lazy(() => import("../pages/sales/Print/PreviewQuotation"));

const SalesReports = lazy(() => import("../pages/sales/SalesReports"));
const LeadSource = lazy(() => import("../pages/sales/LeadSource"));
const EditLeadSource = lazy(() => import("../pages/sales/EditLeadSource"));
const QuotationStatus = lazy(() => import("../pages/sales/QuotationStatus"));
const EditQuotationStatus = lazy(() => import("../pages/sales/EditQuotationStatus"));
const LeadProcess = lazy(() => import("../pages/sales/LeadProcess"));
const EditLeadProcess = lazy(() => import("../pages/sales/EditLeadProcess"));
const EditQuotationLossReason = lazy(() => import("../pages/sales/EditQuotationLossReason"));
const QuotationLossReasons = lazy(() => import("../pages/sales/QuotationLossReasons"));

const Requests = lazy(() => import("../pages/sales/Requests/Requests.js"));
//Support
const ServiceCallsCalendarView = lazy(() =>
	import("../pages/support/ServiceCallsCalendarView")
);
const ServiceCalls = lazy(() => import("../pages/support/ServiceCalls.js"));
const AddNewServiceCall = lazy(() =>
	import("../pages/support/AddNewServiceCall")
);
const CustomerSchema = lazy(() => import("../pages/support/CustomerSchema"));
const KnowledgeBase = lazy(() => import("../pages/support/KnowledgeBase"));
const ServiceCallStatus = lazy(() =>
	import("../pages/support/ServiceCallStatus")
);
const EditServiceCallStatus = lazy(() =>
	import("../pages/support/EditServiceCallStatus")
);
const ServiceCallType = lazy(() => import("../pages/support/ServiceCallType"));
const EditServiceCallType = lazy(() =>
	import("../pages/support/EditServiceCallType")
);

const ServiceCallCategory = lazy(() => import("../pages/support/ServiceCallCategory"));
const EditServiceCallCategory = lazy(() =>
	import("../pages/support/EditServiceCallCategory")
);

//Company
const Brands = lazy(() => import("../pages/company/Brand/Brands"));
const AddNewBrand = lazy(() => import("../pages/company/Brand/AddNewBrand"));
const EditBrand = lazy(() => import("../pages/company/Brand/EditBrand"));
const Outlets = lazy(() => import("../pages/company/Outlet/Outlets"));
const AddNewOutlet = lazy(() => import("../pages/company/Outlet/AddNewOutlet"));
const EditOutlet = lazy(() => import("../pages/company/Outlet/EditOutlet"));

//Settings
const Translations = lazy(() => import("../pages/settings/Translations"));
const Automations = lazy(() => import("../pages/settings/Automations"));
const AddAutomation = lazy(() => import("../pages/settings/AddAutomation"));
const EditAutomation = lazy(() => import("../pages/settings/EditAutomation"));

const ImportTranslations = lazy(() =>
	import("../pages/settings/ImportTranslations")
);
const CategorySections = lazy(() => import("../pages/settings/CategorySections"));
const PageModule = lazy(() => import("../pages/settings/PageModule"));
const EditPageModule = lazy(() => import("../pages/settings/EditPageModule"));
const AddPageModule = lazy(() => import("../pages/settings/AddPageModule"));
const RouteNames = lazy(() => import("../pages/settings/RouteNames"));
const Cities = lazy(() => import("../pages/settings/Cities"));
const Zones = lazy(() => import("../pages/settings/Zones"));
const EditZone = lazy(() => import("../pages/settings/EditZone"));
const SaleRegions = lazy(() => import("../pages/settings/SaleRegions"));
const EditSaleRegion = lazy(() => import("../pages/settings/EditSaleRegion"));

//Users
const Users = lazy(() => import("../pages/users/Users"));
const AddNewUser = lazy(() => import("../pages/users/AddNewUser"));
const EditUser = lazy(() => import("../pages/users/EditUser"));
const UserRoles = lazy(() => import("../pages/users/UserRoles"));
const AddNewUserRole = lazy(() => import("../pages/users/AddNewUserRole"));
const EditUserRole = lazy(() => import("../pages/users/EditUserRole"));

//Restaurants
const Restaurants = lazy(() => import("../pages/restaurant/Restaurants"));
const EditRestaurant = lazy(() => import("../pages/restaurant/EditRestaurant"));
const AddRestaurant = lazy(() => import("../pages/restaurant/AddNewRestaurant"));

//Restaurants
const Branches = lazy(() => import("../pages/branch/Branches"));
const EditBranch = lazy(() => import("../pages/branch/EditBranch"));
const AddBranch = lazy(() => import("../pages/branch/AddNewBranch"));

//Session Ended
const SessionEnded = lazy(() => import("../pages/error/SessionEnded"));
//Forbidden
const Forbidden = lazy(() => import("../pages/error/Forbidden"));

const { Content } = Layout;
function DefaultLayout({ headerHidden, updateHeaderHidden, navHidden, updateNavHidden }) {

	const location = useLocation();

	useEffect(() => {
		updateHeaderHidden(false);
		updateNavHidden(false);
	}, [location.pathname])

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Sidenav />
			<Layout className="site-layout">

				<Content style={{ margin: "5px 20px 15px 20px" }}>

					{!headerHidden && <Header />}
					{!navHidden && <TopNavigation />}
					<Switch>
						<Route exact path="/" render={() => <Redirect to="/dashboard" />} />
						<UserRoute
							exact
							path="/dashboard"
							component={Dashboard}
							accessPermission="dashboard"
						/>

						<UserRoute
							exact
							path="/products"
							component={Products}
							accessPermission="getProducts"
						/>
						<UserRoute
							exact
							path="/products-display"
							component={ProductsDisplay}
							accessPermission="getGroupProducts"
						/>
						<UserRoute
							exact
							path="/add-new-product"
							component={AddNewProduct}
							accessPermission="addProduct"
						/>
						<UserRoute
							exact
							path="/edit-product/:id"
							component={EditProduct}
							accessPermission="editProduct"
						/>
						<UserRoute
							exact
							path="/product-categories"
							component={ProductCategories}
							accessPermission="getProductCategories"
						/>

						<UserRoute
							exact
							path="/edit-product-category/:id"
							component={EditProductCategory}
							accessPermission="editProductCategory"
						/>
						<UserRoute
							exact
							path="/product-units"
							component={ProductUnits}
							accessPermission="getProductUnits"
						/>
						<UserRoute
							exact
							path="/edit-product-unit/:id"
							component={EditProductUnit}
							accessPermission="editProductUnit"
						/>

						<UserRoute
							exact
							path="/product-types"
							component={ProductTypes}
							accessPermission="getProductTypes"
						/>
						<UserRoute
							exact
							path="/edit-product-type/:id"
							component={EditProductType}
							accessPermission="editProductType"
						/>
						<UserRoute
							exact
							path="/edit-product-brand/:id"
							component={EditProductBrand}
							accessPermission="editProductBrand"
						/>
						<UserRoute
							exact
							path="/product-groups"
							component={ProductGroups}
							accessPermission="getProductGroups"
						/>
						<UserRoute
							exact
							path="/edit-product-group/:id"
							component={EditProductGroup}
							accessPermission="editProductGroup"
						/>
						<UserRoute
							exact
							path="/product-brands"
							component={ProductBrands}
							accessPermission="getProductBrands"
						/>
						<UserRoute
							exact
							path="/product-plans"
							component={ProductPlans}
							accessPermission="getProductPlans"
						/>
						<UserRoute
							exact
							path="/product-tags"
							component={ProductTags}
							accessPermission="getProductTags"
						/>

						<UserRoute
							exact
							path="/customers"
							component={Customers}
							accessPermission="getCustomers"
						/>
						<UserRoute
							exact
							path="/customers-maps"
							component={CustomersLocations}
							accessPermission="getCustomersLocations"
						/>
						<UserRoute
							exact
							path="/add-new-customer"
							component={AddNewCustomer}
							accessPermission="addCustomer"
						/>
						<UserRoute
							exact
							path="/edit-customer/:id"
							component={EditCustomer}
							accessPermission="editCustomer"
						/>
						<UserRoute
							exact
							path="/import-customers"
							component={ImportCustomers}
							accessPermission="importCustomer"
						/>
						<UserRoute exact path="/business-card"
							component={BusinessCard}
							accessPermission="getBusinessCards"
						/>
						<UserRoute exact path="/add-business-card"
							component={AddBusinessCard}
							accessPermission="addBusinessCard"
						/>
						<UserRoute exact path="/edit-business-card/:uuid"
							component={EditBusinessCard}
							accessPermission="editBusinessCard"
						/>
						<UserRoute
							exact
							path="/customer-groups"
							component={CustomerGroups}
							accessPermission="getCustomerGroups"
						/>
						<UserRoute
							exact
							path="/edit-customer-group/:id"
							component={EditCustomerGroup}
							accessPermission="editCustomerGroup"
						/>
						<UserRoute
							exact
							path="/customer-outlets"
							component={CustomerOutlets}
							accessPermission="getOutlets"
						/>
						<UserRoute
							exact
							path="/add-new-customer-outlet"
							component={AddNewCustomerOutlet}
							accessPermission="addOutlet"
						/>
						<UserRoute
							exact
							path="/edit-customer-outlet/:id"
							component={EditCustomerOutlet}
							accessPermission="editOutlet"
						/>
						<UserRoute
							exact
							path="/customers-users"
							component={CustomersUsers}
							accessPermission="getCustomersUsers"
						/>

						<UserRoute
							exact
							path="/invoices"
							component={Invoice}
							accessPermission="getInvoices"
						/>
						<UserRoute
							exact
							path="/license"
							component={License}
							accessPermission="getOrganisations"
						/>
						<UserRoute
							exact
							path="/requests"
							component={Requests}
							accessPermission="getRequests"
						/>
						<UserRoute
							exact
							path="/payments"
							component={Payments}
							accessPermission="getPaymentTypes"
						/>
						<UserRoute
							exact
							path="/tax"
							component={Tax}
							accessPermission="getTaxes"
						/>
						<UserRoute
							exact
							path="/edit-tax/:id"
							component={EditTax}
							accessPermission="editTax"
						/>
						<UserRoute
							exact
							path="/currencies"
							component={Currencies}
							accessPermission="getCurrenciesRate"
						/>
						<UserRoute
							exact
							path="/finance-reports"
							component={FinanceReports}
							accessPermission="none"
						/>

						<UserRoute
							exact
							path="/suppliers"
							component={Suppliers}
							accessPermission="getSuppliers"
						/>
						<UserRoute
							exact
							path="/add-new-supplier"
							component={AddNewSupplier}
							accessPermission="addSupplier"
						/>
						<UserRoute
							exact
							path="/edit-supplier/:id"
							component={EditSupplier}
							accessPermission="editSupplier"
						/>
						<UserRoute
							exact
							path="/warehouses"
							component={Warehouses}
							accessPermission="getWarehouses"
						/>
						<UserRoute
							exact
							path="/add-new-warehouse"
							component={AddNewWarehouse}
							accessPermission="addWarehouse"
						/>
						<UserRoute
							exact
							path="/edit-warehouse/:id"
							component={EditWarehouse}
							accessPermission="editWarehouse"
						/>
						<UserRoute
							exact
							path="/purchases"
							component={Purchases}
							accessPermission="getPurchases"
						/>
						<UserRoute
							exact
							path="/transfers"
							component={Transfers}
							accessPermission="getTransfers"
						/>

						<UserRoute
							exact
							path="/page-module"
							component={PageModule}
							accessPermission="getAdminPage"
						/>

						<UserRoute
							exact
							path="/edit-page-module/:id"
							component={EditPageModule}
							accessPermission="editAdminPage"
						/>
						<UserRoute
							exact
							path="/add-page-module"
							component={AddPageModule}
							accessPermission="addAdminPage"
						/>
						<UserRoute
							exact
							path="/route-names"
							component={RouteNames}
							accessPermission="getRoutes"
						/>
						<UserRoute
							exact
							path="/stock-count"
							component={StockCount}
							accessPermission="getStockCounts"
						/>
						<UserRoute
							exact
							path="/stock-reports"
							component={StockReports}
							accessPermission="getStockReports"
						/>

						<UserRoute
							exact
							path="/sales"
							component={Sales}
							accessPermission="getSales"
						/>
						<UserRoute
							exact
							path="/add-new-sale"
							component={AddNewSale}
							accessPermission="addSale"
						/>
						<UserRoute
							exact
							path="/edit-sale/:id"
							component={EditSale}
							accessPermission="editSale"
						/>
						<UserRoute
							exact
							path="/leads"
							component={Leads}
							accessPermission="getLeads"
						/>
						<UserRoute
							exact
							path="/add-new-lead"
							component={AddNewLead}
							accessPermission="addLead"
						/>
						<UserRoute
							exact
							path="/edit-lead/:id"
							component={EditLead}
							accessPermission="editLead"
						/>
						<UserRoute
							exact
							path="/quotations"
							component={Quotations}
							accessPermission="getQuotations"
						/>
						<UserRoute
							exact
							path="/add-quotation"
							component={AddQuotation}
							accessPermission="addQuotation"
						/>
						<UserRoute
							exact
							path="/receipts"
							component={Receipts}
							accessPermission="getReceipts"
						/>
						<UserRoute
							exact
							path="/add-invoice"
							component={AddInvoice}
							accessPermission="addInvoice"
						/>
						<UserRoute
							exact
							path="/edit-invoice/:id"
							component={EditInvoice}
							accessPermission="editInvoice"
						/>
						<UserRoute
							exact
							path="/edit-quotation/:id?ref=:ref"
							component={EditQuotation}
							accessPermission="editQuotation"
						/>
						<UserRoute
							exact
							path="/edit-quotation/:id"
							component={EditQuotation}
							accessPermission="editQuotation"
						/>
						<UserRoute
							exact
							path="/preview-quotation/:id"
							component={PreviewQuotation}
							accessPermission="getQuotationParameters"
						/>
						<UserRoute
							exact
							path="/preview-invoice/:id"
							component={PreviewInvoice}
							accessPermission="getInvoiceParameters"
						/>

						<UserRoute
							exact
							path="/sales-reports"
							component={SalesReports}
							accessPermission="getSalesReports"
						/>
						<UserRoute
							exact
							path="/lead-source"
							component={LeadSource}
							accessPermission="getLeadSources"
						/>
						<UserRoute
							exact
							path="/edit-lead-source/:id"
							component={EditLeadSource}
							accessPermission="editLeadSource"
						/>
						<UserRoute
							exact
							path="/quotation-status"
							component={QuotationStatus}
							accessPermission="getQuotationStatuses"
						/>
						<UserRoute
							exact
							path="/quotation-loss-reasons"
							component={QuotationLossReasons}
							accessPermission="getQuotationLostReasons"
						/>
						<UserRoute
							exact
							path="/edit-quotation-loss-reason/:id"
							component={EditQuotationLossReason}
							accessPermission="editQuotationLostReason"
						/>
						<UserRoute
							exact
							path="/edit-quotation-status/:id"
							component={EditQuotationStatus}
							accessPermission="editQuotationStatus"
						/>
						<UserRoute
							exact
							path="/lead-process"
							component={LeadProcess}
							accessPermission="getLeadProcesses"
						/>
						<UserRoute
							exact
							path="/edit-lead-process/:id"
							component={EditLeadProcess}
							accessPermission="editLeadProcess"
						/>


						<UserRoute
							exact
							path="/service-tasks-calendar-view"
							component={ServiceCallsCalendarView}
							accessPermission="getServiceCalls"
						/>
						<UserRoute
							exact
							path="/service-tasks"
							component={ServiceCalls}
							accessPermission="getServiceCalls"
						/>


						<UserRoute
							exact
							path="/add-new-service-task"
							component={AddNewServiceCall}
							accessPermission="addServiceCall"
						/>

						<UserRoute
							exact
							path="/sales-tasks"
							component={SalesTasks}
							accessPermission="getSalesCalls"
						/>

						<UserRoute
							exact
							path="/add-new-sales-task"
							component={AddNewServiceCall}
							accessPermission="addSalesCall"
						/>

						<UserRoute
							exact
							path="/customer-schema"
							component={CustomerSchema}
							accessPermission="getCustomerSchema"
						/>
						<UserRoute
							exact
							path="/customer-schema?id=:id"
							component={CustomerSchema}
							accessPermission="getCustomerSchema"
						/>
						<UserRoute
							exact
							path="/knowledge-base"
							component={KnowledgeBase}
							accessPermission="getKnowledgeBases"
						/>


						<UserRoute
							exact
							path="/service-task-status"
							component={ServiceCallStatus}
							accessPermission="getServiceCallStatuses"
						/>
						<UserRoute
							exact
							path="/edit-service-task-status/:id"
							component={EditServiceCallStatus}
							accessPermission="editServiceCallStatus"
						/>
						<UserRoute
							exact
							path="/service-task-type"
							component={ServiceCallType}
							accessPermission="getServiceCallTypes"
						/>
						<UserRoute
							exact
							path="/edit-service-task-type/:id"
							component={EditServiceCallType}
							accessPermission="editServiceCallType"
						/>
						<UserRoute
							exact
							path="/support-department"
							component={ServiceCallCategory}
							accessPermission="getServiceCallDepartments"
						/>
						<UserRoute
							exact
							path="/edit-support-department/:id"
							component={EditServiceCallCategory}
							accessPermission="editServiceCallDepartment"
						/>

						<UserRoute
							exact
							path="/brands"
							component={Brands}
							accessPermission="getCompanies"
						/>
						<UserRoute
							exact
							path="/add-new-brand"
							component={AddNewBrand}
							accessPermission="addCompany"
						/>
						<UserRoute
							exact
							path="/edit-brand/:id"
							component={EditBrand}
							accessPermission="editCompany"
						/>
						<UserRoute
							exact
							path="/outlets"
							component={Outlets}
							accessPermission="getBranches"
						/>
						
						<UserRoute
							exact
							path="/add-new-outlet"
							component={AddNewOutlet}
							accessPermission="addBranch"
						/>
						<UserRoute
							exact
							path="/edit-outlet/:id"
							component={EditOutlet}
							accessPermission="editBranch"
						/>

						<UserRoute
							exact
							path="/restaurants/organisationId=:organisationId&customerId=:customerId"
							component={Restaurants}
							accessPermission="getSRestaurants"
						/>
						<UserRoute
							exact
							path="/restaurants/customerId=:customerId"
							component={Restaurants}
							accessPermission="getSRestaurants"
						/>

						<UserRoute
							exact
							path="/edit-restaurant/restId=:restId&organisationId=:organisationId&customerId=:customerId"
							component={EditRestaurant}
							accessPermission="editSRestaurant"
						/>
						<UserRoute
							exact
							path="/edit-restaurant/restId=:restId&customerId=:customerId"
							component={EditRestaurant}
							accessPermission="editSRestaurant"
						/>

						<UserRoute
							exact
							path="/add-new-restaurant/customerId=:customerId&organisationId=:organisationId&customer=:customer"
							component={AddRestaurant}
							accessPermission="addSRestaurant"
						/>
						<UserRoute
							exact
							path="/edit-branch/restId=:restId&organisationId=:organisationId/branchId=:branchId"
							component={EditBranch}
							accessPermission="editSBranch"
						/>
						<UserRoute
							exact
							path="/branches"
							component={Branches}
							accessPermission="getSBranches"
						/>
						<UserRoute
							exact
							path="/add-new-branch/restId=:restId&customerId=:customerId&organisationId=:organisationId"
							component={AddBranch}
							accessPermission="addSBranch"

						/>

						<UserRoute
							exact
							path="/add-new-branch/restId=:restId&organisationId=:organisationId"
							component={AddBranch}
							accessPermission="addSBranch"
						/>
						<UserRoute
							exact
							path="/translations"
							component={Translations}
							accessPermission="getTranslations"
						/>
						<UserRoute
							exact
							path="/automations"
							component={Automations}
							accessPermission="getAutomations"
						/>
						<UserRoute
							exact
							path="/add-automation"
							component={AddAutomation}
							accessPermission="addAutomation"
						/>
						<UserRoute
							exact
							path="/edit-automation/:id"
							component={EditAutomation}
							accessPermission="editAutomation"
						/>
						<UserRoute
							exact
							path="/import-translations"
							component={ImportTranslations}
							accessPermission="uploadTranslations"
						/>
						<UserRoute
							exact
							path="/cities"
							component={Cities}
							accessPermission="getCities"
						/>
						<UserRoute
							exact
							path="/zones"
							component={Zones}
							accessPermission="getZones"
						/>
						<UserRoute
							exact
							path="/edit-zone/:id"
							component={EditZone}
							accessPermission="editZone"
						/>
						<UserRoute
							exact
							path="/sale-regions"
							component={SaleRegions}
							accessPermission="getSaleRegions"
						/>
						<UserRoute
							exact
							path="/edit-sale-region/:id"
							component={EditSaleRegion}
							accessPermission="editSaleRegion"
						/>

						<UserRoute
							exact
							path="/users"
							component={Users}
							accessPermission="getUsers"
						/>
						<UserRoute
							exact
							path="/add-new-user"
							component={AddNewUser}
							accessPermission="addUser"
						/>
						<UserRoute
							exact
							path="/edit-user/:id"
							component={EditUser}
							accessPermission="editUser"
						/>
						<UserRoute
							exact
							path="/user-roles"
							component={UserRoles}
							accessPermission="getRoles"
						/>
						<UserRoute
							exact
							path="/add-new-user-role"
							component={AddNewUserRole}
							accessPermission="addRole"
						/>
						<UserRoute
							exact
							path="/edit-user-role/:id"
							component={EditUserRole}
							accessPermission="editRole"
						/>
						<UserRoute
							exact
							path="/session-ended"
							component={SessionEnded}
							accessPermission="getSessionEndeds"
						/>


						<UserRoute
							exact
							path="/sales-task-status"
							component={SalesCallStatus}
							accessPermission="getSalesCallStatuses"
						/>
						<UserRoute
							exact
							path="/edit-sales-task-status/:id"
							component={EditSalesCallStatus}
							accessPermission="editSalesCallStatus"
						/>
						<UserRoute
							exact
							path="/sales-task-type"
							component={SalesCallType}
							accessPermission="getSalesCallTypes"
						/>
						<UserRoute
							exact
							path="/edit-sales-task-type/:id"
							component={EditSalesCallType}
							accessPermission="editSalesCallType"
						/>
						<UserRoute
							exact
							path="/sales-support-department"
							component={SalesCallCategory}
							accessPermission="getSalesCallDepartments"
						/>
						<UserRoute
							exact
							path="/edit-sales-support-department/:id"
							component={EditSalesCallCategory}
							accessPermission="editSalesCallDepartment"
						/>

						<UserRoute exact path="/forbidden" component={Forbidden} />
						<Route render={() => <Redirect to="/error" />} />
					</Switch>
				</Content>
				<Footer />
			</Layout>
		</Layout>
	);
}

const mapStateToProps = (state) => ({
	accessToken: state.auth.accessToken,
	headerHidden: state.auth.headerHidden,
	navHidden: state.auth.navHidden

});

const mapDispatchToProps = (dispatch) => ({

	updateHeaderHidden: (data) => {
		dispatch(authActions.updateHeaderHidden(data));
	},
	updateNavHidden: (data) => {
		dispatch(authActions.updateNavHidden(data));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
