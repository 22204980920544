import {
    PieChartOutlined,
    DollarOutlined,
    BankOutlined,
} from "@ant-design/icons";

import { ReactComponent as Support } from "../../src/assets/images/support team (2).svg"
import { ReactComponent as Finances } from "../../src/assets/images/finances.svg"
import { ReactComponent as StockManagement } from "../../src/assets/images/stock management.svg"
import { ReactComponent as SupplierIcon } from "../../src/assets/images/supplier.svg"
import { ReactComponent as SupportDepartment } from "../../src/assets/images/support department.svg"
import { ReactComponent as SupportTeam } from "../../src/assets/images/support team.svg"
import { ReactComponent as Tags } from "../../src/assets/images/tags.svg"
import { ReactComponent as TaskType } from "../../src/assets/images/task type.svg"
import { ReactComponent as TasksStatus } from "../../src/assets/images/tasks status.svg"
import { ReactComponent as Tax } from "../../src/assets/images/taxes.svg"
import { ReactComponent as Transfer } from "../../src/assets/images/transfer.svg"
import { ReactComponent as Translation } from "../../src/assets/images/translation.svg"
import { ReactComponent as Users } from "../../src/assets/images/users.svg"
import { ReactComponent as Warehouse } from "../../src/assets/images/warehouse.svg"
import { ReactComponent as Zones } from "../../src/assets/images/zones.svg"
import { ReactComponent as LossReason } from "../../src/assets/images/loss reason.svg"
import { ReactComponent as Module } from "../../src/assets/images/module.svg"
import { ReactComponent as Outlet } from "../../src/assets/images/outlet.svg"
import { ReactComponent as ProductAttributes } from "../../src/assets/images/Product attributes.svg"
import { ReactComponent as ProductCategories } from "../../src/assets/images/Product categories.svg"
import { ReactComponent as ProductDisplay } from "../../src/assets/images/Product display.svg"
import { ReactComponent as ProductType } from "../../src/assets/images/Product type.svg"
import { ReactComponent as ProductUnit } from "../../src/assets/images/product unit.svg"
import { ReactComponent as ProductGroups } from "../../src/assets/images/product-group.svg"
import { ReactComponent as Products } from "../../src/assets/images/products.svg"
import { ReactComponent as Purchase } from "../../src/assets/images/purchase.svg"
import { ReactComponent as Quotation } from "../../src/assets/images/quotations.svg"
import { ReactComponent as QuotationStatus } from "../../src/assets/images/quotations status.svg"
import { ReactComponent as Receipts } from "../../src/assets/images/receipts.svg"
import { ReactComponent as Region } from "../../src/assets/images/region.svg"
import { ReactComponent as Reports } from "../../src/assets/images/reports.svg"
import { ReactComponent as SalesDepartment } from "../../src/assets/images/sales department.svg"
import { ReactComponent as SalesTransactions } from "../../src/assets/images/sales transactions.svg"
import { ReactComponent as Sales } from "../../src/assets/images/sales.svg"
import { ReactComponent as SalesTeam } from "../../src/assets/images/sales-team.svg"
import { ReactComponent as ServiceTask } from "../../src/assets/images/service-task (support-sales).svg"
import { ReactComponent as Settings } from "../../src/assets/images/settings.svg"
import { ReactComponent as StockCount } from "../../src/assets/images/stock count.svg"
import { ReactComponent as Api } from "../../src/assets/images/api.svg"
import { ReactComponent as Brand } from "../../src/assets/images/Brand.svg"
import { ReactComponent as Brands } from "../../src/assets/images/brands.svg"
import { ReactComponent as BusinessCards } from "../../src/assets/images/business-cards.svg"
import { ReactComponent as Cities } from "../../src/assets/images/cities.svg"
import { ReactComponent as Currencies } from "../../src/assets/images/currencies.svg"
import { ReactComponent as CustomerSchema } from "../../src/assets/images/customer schema.svg"
import { ReactComponent as CustomerAttributes } from "../../src/assets/images/customer-attributes.svg"
import { ReactComponent as Customers } from "../../src/assets/images/customers.svg"
import { ReactComponent as CustomersGroup } from "../../src/assets/images/customers-group.svg"
import { ReactComponent as Finance } from "../../src/assets/images/finance.svg"
import { ReactComponent as Invoice } from "../../src/assets/images/invoice.svg"
import { ReactComponent as KnowledgeBase } from "../../src/assets/images/knowledge base.svg"
import { ReactComponent as LeadProcess } from "../../src/assets/images/leads process.svg"
import { ReactComponent as LeadSource } from "../../src/assets/images/leads source.svg"
import { ReactComponent as Leads } from "../../src/assets/images/leads.svg"
import { ReactComponent as Licenses } from "../../src/assets/images/license.svg"
import { ReactComponent as Locations } from "../../src/assets/images/location.svg"
import { ReactComponent as Automations } from "../../src/assets/images/automation.svg"
import { ReactComponent as Payments } from "../../src/assets/images/payment.svg"
import { ReactComponent as StockSetup } from "../../src/assets/images/stock setup.svg"
import { ReactComponent as StockTransactions } from "../../src/assets/images/stock transactions.svg"

export const routes = [
    {
        name: "Dashboard",
        key: "dashboard",
        type: "link", //one page vertically
        route: "/dashboard",
        accessName: "dashboard",
        icon: <PieChartOutlined />,
    },
    {
        name: "Products",
        key: "products",
        type: "subMenu", //vertically
        route: "/",
        routes: [
            "/products",
            "/product-categories",
            "/product-types",
            "/product-groups",
            "/product-brands",
            "/product-units",
            "/product-tags",
            "/products-display"
        ],
        accessChilds: [
            "getProducts",
            "getProductCategories",
            "getProductTypes",
            "getProductGroups",
            "getProductBrands",
            "getProductTags",
            "getProductUnits",
            "getGroupProducts"
        ],
        icon: <Products />,
        content: [
            {
                name: "Product Attributes",
                icon: <ProductAttributes />,
                key: "productAttributes",
                route: "/",
                routes: [
                    "/products",
                    "/product-categories",
                    "/product-types",
                    "/product-groups",
                    "/product-brands",
                    "/product-units",
                    "/product-tags",
                ],
                type: "layout", //has many subpages as tabs
                accessChilds: [
                    "getProducts",
                    "getProductCategories",
                    "getProductTypes",
                    "getProductGroups",
                    "getProductBrands",
                    "getProductUnits",
                    "getProductTags"
                ],
                content: [
                    {
                        name: "Products",
                        key: "allProducts",
                        route: "/products",
                        accessName: "getProducts",
                        order: 1,
                        icon: <Products />
                    },
                    {
                        name: "Groups",
                        key: "productGroups",
                        route: "/product-groups",
                        accessName: "getProductGroups",
                        icon: <ProductGroups />
                    },
                    {
                        name: "Categories",
                        key: "productCategories",
                        route: "/product-categories",
                        accessName: "getProductCategories",
                        icon: <ProductCategories />

                    },
                    {
                        name: "Types",
                        key: "productTypes",
                        route: "/product-types",
                        accessName: "getProductTypes",
                        icon: <ProductType />
                    },

                    {
                        name: "Brands",
                        key: "productBrands",
                        route: "/product-brands",
                        accessName: "getProductBrands",
                        icon: <Brands />

                    },
                    {
                        name: "Units",
                        key: "productUnits",
                        route: "/product-units",
                        accessName: "getProductUnits",
                        icon: <ProductUnit />
                    },
                    {
                        name: "Tags",
                        key: "productTags",
                        route: "/product-tags",
                        accessName: "getProductTags",
                        icon: <Tags />
                    },

                ]
            },
            {
                name: "Products Display",
                key: "productsDisplay",
                route: "/products-display",
                accessName: "getGroupProducts",
                type: "link",//no subpage
                icon: <ProductDisplay />
            },

        ],
    },
    {
        name: "Customers",
        key: "customers",
        type: "subMenu",
        route: "/",
        routes: [
            "/customers-maps",
            "/customers",
            "/customer-groups",
            "/customers-users",
            "/business-card",
        ],
        accessChilds: ["getCustomers", "getCustomerGroups", "getCustomersUsers", "getBusinessCards", "getCustomersLocations"],
        icon: <Customers />,
        content: [
            {
                name: "Customer Attributes",
                key: "customerAttributes",
                icon: <CustomerAttributes />,
                route: "/",
                type: "layout",
                routes: [
                    "/customers-maps",
                    "/customers",
                    "/customer-groups",
                    "/customers-users",
                    "/business-card",
                ],
                accessChilds: ["getCustomers", "getCustomerGroups", "getCustomersUsers", "getBusinessCards", "getCustomersLocations"],
                content: [
                    {
                        name: "Customers",
                        key: "allCustomers",
                        route: "/customers",
                        accessName: "getCustomers",
                        icon: <Customers />
                    },
                    {
                        name: "Customer Groups",
                        key: "customerGroups",
                        route: "/customer-groups",
                        accessName: "getCustomerGroups",
                        icon: <CustomersGroup />
                    },
                    {
                        name: "Customers Maps",
                        key: "customersMaps",
                        route: "/customers-maps",
                        accessName: "getCustomersLocations",
                        icon: <CustomersGroup />
                    },
                    {
                        name: "Users",
                        key: "customersUsers",
                        route: "/customers-users",
                        accessName: "getCustomersUsers",
                        icon: <Users />
                    },
                    {
                        name: 'Business Cards',
                        key: "businessCards",
                        route: '/business-card',
                        accessName: 'getBusinessCards',
                        icon: <BusinessCards />

                    }
                ],
            },


        ],
    },
    {
        name: "Finance"
        ,
        type: "subMenu",
        key: "finance",
        icon: <Finances />,
        route: "/",
        routes: [
            "/invoices",
            "/license",
            "/receipts",
            "/finance-reports",
            "/payments",
            "/tax",
            "/currencies",
            "/requests"
        ],
        accessChilds: [
            "getInvoices",
            "getOrganisations",
            "getReceipts",
            "getFinanceReports",
            "getPaymentTypes",
            "getCurrenciesRate",
            "getTaxes",
        ],
        content: [
            {
                name: "Sales Transactions",
                type: "layout",
                routes: [
                    "/invoices",
                    "/license",
                    "/receipts"
                ],
                key: "salesTransactions",
                icon: <SalesTransactions />,
                route: "/",
                accessChilds: [
                    "getInvoices",
                    "getOrganisations",
                    "getReceipts"
                ],
                content: [
                    {
                        name: "Invoice",
                        key: "allInvoices",
                        route: "/invoices",
                        accessName: "getInvoices",
                        icon: <Invoice />
                    },
                    {
                        name: "Licenses",
                        key: "licenses",
                        route: "/license",
                        accessName: "getOrganisations",
                        icon: <Licenses />
                    },
                    {
                        name: "Receipts",
                        key: "receipts",
                        route: "/receipts",
                        accessName: "getReceipts",
                        icon: <Receipts />
                    },
                    
                ]
            },

            {
                name: "Reports",
                key: "reports",
                route: "/finance-reports",
                accessName: "getFinanceReports",
                icon: <Reports />
            },
            {
                name: "Settings",
                key: "financeSettings",
                type: "layout",
                route: "/",
                icon: <Settings />,
                routes: [
                    "/payments",
                    "/tax",
                    "/currencies",
                ],
                accessChilds: [
                    "getPaymentTypes",
                    "getCurrenciesRate",
                    "getTaxes"
                ],
                content: [
                    {
                        name: "Payments",
                        key: "payments",
                        route: "/payments",
                        accessName: "getPaymentTypes",
                        icon: <Payments />
                    },
                    {
                        name: "Tax",
                        key: "tax",
                        route: "/tax",
                        accessName: "getTaxes",
                        icon: <Tax />
                    },
                    {
                        name: "Currencies",
                        key: "currencies",
                        route: "/currencies",
                        accessName: "getCurrenciesRate",
                        icon: <Currencies />
                    }
                ],
            },
        ],
    },
    {
        name: "Stock Management",
        key: "stockManagement",
        type: "subMenu",
        route: "/",
        routes: [
            "/suppliers",
            "/warehouses",
            "/purchases",
            "/transfers",
            "/stock-count",
            "/stock-reports"
        ],
        accessChilds: [
            "getSuppliers",
            "getWarehouses",
            "getPurchases",
            "getTransfers",
            "getStockCount",
            "getStockReports",
        ],
        icon: <StockManagement />,
        content: [
            {
                name: "Stock Setup",
                key: "stockSetup",
                type: "layout",
                icon: <StockSetup />,
                route: "/",
                routes: [
                    "/suppliers",
                    "/warehouses"
                ],
                accessChilds: [
                    "getSuppliers",
                    "getWarehouses",
                ],
                content: [
                    {
                        name: "Suppliers",
                        key: "suppliers",
                        route: "/suppliers",
                        accessName: "getSuppliers",
                        icon: <SupplierIcon />
                    },
                    {
                        name: "Warehouses",
                        key: "warehouses",
                        route: "/warehouses",
                        accessName: "getWarehouses",
                        icon: <Warehouse />
                    },
                ]
            },
            {
                name: "Stock Transactions",
                icon: <StockTransactions />,
                route: "/",
                type: "layout",
                routes: [
                    "/purchases",
                    "/transfers",
                    "/stock-count",
                ],
                accessChilds: [
                    "getPurchases",
                    "getTransfers",
                    "getStockCount",
                ],
                key: "stockTransactions",
                content: [
                    {
                        name: "Purchases",
                        key: "purchases",
                        route: "/purchases",
                        accessName: "getPurchases",
                        icon: <Purchase />
                    },

                    {
                        name: "Transfers",
                        key: "transfers",
                        route: "/transfers",
                        accessName: "getTransfers",
                        icon: <Transfer />
                    },
                    {
                        name: "Stock Count",
                        key: "stockCount",
                        route: "/stock-count",
                        accessName: "getStockCount",
                        icon: <StockCount />
                    },
                ]
            },
            {
                name: "Reports",
                key: "stockReports",
                route: "/stock-reports",
                accessName: "getStockReports",
                icon: <Reports />
            },

        ],
    },
    {
        name: "Sales",
        key: "salesTeam",
        type: "subMenu",
        icon: <Sales />,
        route: "/",
        routes: [
            "/sales-reports",
            "/leads",
            "/quotations",

            "/sales-tasks",

            "/sales-task-status",
            "/sales-task-type",
            "/sales-support-department",
            "/lead-source",
            "/lead-process",
            "/quotation-status",
            "/quotation-loss-reasons",
            "/requests"

        ],
        accessChilds: [
            "getLeads",
            "getQuotations",
            "getSalesReports",

            "getSalesCalls",

            "getLeadSources",
            "getQuotationStatuses",
            "getLeadProcesses",

            "getSalesCallStatuses",
            "getSalesCallTypes",
            "getSalesCallDepartments",
            "getQuotationLostReasons",
            
            "getRequests"
        ],
        icon: <DollarOutlined />,
        content: [
            {
                name: "Sales Team CRM",
                type: "layout",
                key: "CRM",
                route: "/",
                icon: <SalesTeam />,
                routes: [
                    "/leads",
                    "/quotations",
                    "/sales-tasks",
                    "/requests"

                ],
                accessChilds: [
                    "getLeads",
                    "getQuotations",
                    "getSalesCalls",
                    "getRequests"
                ],
                content: [
                    {
                        name: "Leads",
                        key: "leads",
                        route: "/leads",
                        accessName: "getLeads",
                        icon: <Leads />
                    },
                    {
                        name: "Quotations",
                        key: "quotations",
                        route: "/quotations",
                        accessName: "getQuotations",
                        icon: <Quotation />
                    },

                    {
                        name: "Sales Tasks",
                        key: "salesTasks",
                        route: "/sales-tasks",
                        accessName: "getSalesCalls",
                        icon: <ServiceTask />
                    },
                    {
                        name: "Requests",
                        key: "requests",
                        route: "/requests",
                        accessName: "getRequests",
                        icon: <Licenses />
                    },
                ]
            },
            {
                name: "Reports",
                key: "salesReports",
                route: "/sales-reports",
                accessName: "getSalesReports",
                icon: <Reports />
            },

            {
                name: "Settings",
                type: "layout",
                key: "salesSettings",
                icon: <Settings />,
                route: "/",
                routes: [
                    "/sales-task-status",
                    "/sales-task-type",
                    "/sales-support-department",

                    "/lead-source",
                    "/lead-process",
                    "/quotation-status",
                    "/quotation-loss-reasons"
                ],
                accessChilds: [
                    "getLeadSources",
                    "getQuotationStatuses",
                    "getLeadProcesses",

                    "getSalesCallStatuses",
                    "getSalesCallTypes",
                    "getSalesCallDepartments",
                    "getQuotationLostReasons"
                ],
                content: [


                    {
                        name: "Sales Task Status",
                        key: "salesStatus",
                        route: "/sales-task-status",
                        accessName: "getSalesCallStatuses",
                        icon: <TasksStatus />,
                    },
                    {
                        name: "Sales Task Type",
                        key: "salesType",
                        route: "/sales-task-type",
                        accessName: "getSalesCallTypes",
                        icon: <TaskType />
                    },
                    {
                        name: "Sales Department",
                        key: "salesDepartment",
                        route: "/sales-support-department",
                        accessName: "getSalesCallDepartments",
                        icon: <SalesDepartment />
                    },
                    {
                        name: "Lead Source",
                        key: "leadSource",
                        route: "/lead-source",
                        accessName: "getLeadSources",
                        icon: <LeadSource />
                    },

                    {
                        name: "Lead Process",
                        key: "leadProcess",
                        route: "/lead-process",
                        accessName: "getLeadProcesses",
                        icon: <LeadProcess />
                    },
                    {
                        name: "Quotation Status",
                        key: "quotationStatus",
                        route: "/quotation-status",
                        accessName: "getQuotationStatuses",
                        icon: <QuotationStatus />
                    },
                    {
                        name: "Loss Reasons",
                        key: "lossReasons",
                        route: "/quotation-loss-reasons",
                        accessName: "getQuotationLostReasons",
                        icon: <LossReason />
                    },
                ],
            },
        ],
    },
    {
        name: "Support Team",
        key: "supportTeam",
        type: "subMenu",
        route: "/",
        routes: [
            "/service-tasks",
            "/knowledge-base",
            "/customer-schema",
            "/service-task-status",
            "/service-task-type",
            "/support-department"
        ],
        accessChilds: [
            "getServiceCalls",
            "getAllCustomerSchema",
            "getKnowledgeBases",
            "getServiceCallStatuses",
            "getServiceCallTypes",
            "getServiceCallDepartments"
        ],
        icon: <Support />,
        content: [
            {
                name: "Support",
                type: "layout",
                key: "support",
                route: "/",
                routes: [
                    "/service-tasks",
                    "/knowledge-base",
                    "/customer-schema"
                ],
                icon: <SupportTeam />,
                accessChilds: [
                    "getServiceCalls",
                    "getKnowledgeBases",
                    "getAllCustomerSchema"
                ],
                content: [
                    {
                        name: "Service Tasks",
                        key: "serviceTasks",
                        route: "/service-tasks",
                        accessName: "getServiceCalls",
                        icon: <ServiceTask />
                    },
                    {
                        name: "Customers Schema",
                        key: "customerSchema",
                        route: "/customer-schema",
                        accessName: "getAllCustomerSchema",
                        icon: <CustomerSchema />
                    },
                    {
                        name: "Knowledge Base",
                        key: "knowledgeBase",
                        route: "/knowledge-base",
                        accessName: "getKnowledgeBases",
                        icon: <KnowledgeBase />
                    },
                ]
            },

            {
                name: "Settings",
                key: "serviceSettings",
                type: "layout",
                icon: <Settings />,
                route: "/",
                routes: [
                    "/service-task-status",
                    "/service-task-type",
                    "/support-department"
                ],
                accessChilds: [
                    "getServiceCallStatuses",
                    "getServiceCallTypes",
                    "getServiceCallDepartments"
                ],
                content: [

                    {
                        name: "Service Task Status",
                        key: "serviceStatus",
                        route: "/service-task-status",
                        accessName: "getServiceCallStatuses",
                        icon: <TasksStatus />,
                    },
                    {
                        name: "Service Task Type",
                        key: "serviceType",
                        route: "/service-task-type",
                        accessName: "getServiceCallTypes",
                        icon: <TaskType />
                    },
                    {
                        name: "Support Department",
                        key: "serviceDepartment",
                        route: "/support-department",
                        accessName: "getServiceCallDepartments",
                        icon: <SupportDepartment />
                    },
                ],
            },
        ],
    },
    {
        name: "Company",
        key: "company",
        type: "subMenu",
        route: "/",
        routes: [
            "/brands",
            "/outlets"

        ],
        accessChilds: ["getCompanies", "getBranches"],
        icon: <BankOutlined />,
        content: [
            {
                name: "Brands",
                key: "brands",
                route: "/brands",
                accessName: "getCompanies",
                icon: <Brand />
            },
            {
                name: "Outlet",
                key: "outlets",
                route: "/outlets",
                accessName: "getBranches",
                icon: <Outlet />
            },
        ],
    },
    {
        name: "Settings",
        type: "subMenu",
        key: "settings",
        route: "/",
        routes: [
            "/translations",
            "/page-module",
            "/route-names",
            "/automations",
            "/cities",
            "/zones",
            "/sale-regions"
        ],
        accessChilds: [
            "getTranslations",
            "getCities",
            "getZones",
            "getSaleRegions",
            "getAdminPage",
            "getRoutes",
            "getAutomations"
        ],
        icon: <Settings />,
        content: [
            {
                name: "Admin Settings",
                type: "layout",
                key: "adminSettings",
                route: "/",
                icon: <Settings />,
                routes: [
                    "/translations",
                    "/page-module",
                    "'route-names"
                ],
                accessChilds: [
                    "getTranslations",
                    "getAdminPage",
                    "getRoutes",
                ],
                content: [
                    {
                        name: "Translations",
                        key: "translations",
                        route: "/translations",
                        accessName: "getTranslations",
                        icon: <Translation />
                    },
                    {
                        name: "Page Module",
                        key: "pageModule",
                        route: "/page-module",
                        accessName: "getAdminPage",
                        icon: <Module />
                    },
                    {
                        name: "Api Names",
                        key: "apiNames",
                        route: "/route-names",
                        accessName: "getRoutes",
                        icon: <Api />
                    },]
            },
            {
                name: "Automations",
                key: "automations",
                route: "/automations",
                accessName: "getAutomations",
                icon: <Automations />
            },

            {
                name: "Locations",
                key: "locations",
                icon: <Locations />,
                type: "layout",
                route: "/",
                routes: [
                    "/cities",
                    "/zones",
                    "/sale-regions"

                ],
                accessChilds: [
                    "getCities",
                    "getZones",
                    "getSaleRegions",
                ],
                content: [
                    {
                        name: "Cities",
                        key: "cities",
                        route: "/cities",
                        accessName: "getCities",
                        icon: <Cities />
                    },
                    {
                        name: "Zones",
                        key: "zones",
                        route: "/zones",
                        accessName: "getZones",
                        icon: <Zones />
                    },
                    {
                        name: "Sale Regions",
                        key: "saleRegions",
                        route: "/sale-regions",
                        accessName: "getSaleRegions",
                        icon: <Region />
                    },

                ]
            }

        ],
    },
];